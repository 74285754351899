<template>
	<div>
		<div v-if="isOwner" class="sm:flex-wrap md:flex">
			<nav class="md:w-1/4 sm:w-full pr-3">
				<router-link
					active-class="bg-gray-100 text-gray-900 focus:bg-gray-200"
					exact
					class="mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150"
					:to="{ name: 'billing-settings' }"
				>
					Billing
				</router-link>
				<router-link
					v-if="subscription"
					active-class="bg-gray-100 text-gray-900 focus:bg-gray-200"
					eaxt
					class="mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150"
					:to="{ name: 'license-settings' }"
				>
					Licenses
				</router-link>
			</nav>
			<div class=" md:w-3/4 sm:w-full">
				<router-view></router-view>
			</div>
		</div>
		<div v-else>
			<div>
				<h2>Access Denied</h2>
				<p>Account Settings are limited to the account owner</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'AccountOverview',
	computed: {
		...mapGetters('accounts', ['isOwner', 'account']),
		subscription: function() {
			return this.account.subscription
		}
	}
}
</script>

<style></style>
